import { graphql, Link, StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Image from "gatsby-image"
import HeaderSmall from "./headerSmall"
import Layout from "./layout"

export const tblStarImage3 = graphql`
    fragment tblStarImage3 on File {
        childImageSharp {
            fluid(maxWidth: 101) {
                ...GatsbyImageSharpFluid
            }
        }
    }
`

export const tblStarImage2 = graphql`
  fragment tblStarImage2 on File {
    childImageSharp {
      fixed(width: 52, height: 23) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`

export const tblStarImage = graphql`
    fragment tblStarImage on File {
        childImageSharp {
            fixed(width: 100, height: 63) {
                ...GatsbyImageSharpFixed
            }
        }
    }
`

const ComparisonTable = ({objList,siteSection, Year, betterThan, showHeaders, showProvider}) => (
  <StaticQuery
    query={graphql`
      query {
        defaqto1: file(relativePath: { eq: "2020/defaqto_alt1.png" }) {
          ...tblStarImage3
        },
        defaqto2: file(relativePath: { eq: "2020/defaqto_alt2.png" }) {
          ...tblStarImage3
        },
        defaqto3: file(relativePath: { eq: "2020/defaqto_alt3.png" }) {
          ...tblStarImage3
        },
        defaqto4: file(relativePath: { eq: "2020/defaqto_alt4.png" }) {
          ...tblStarImage3
        },
        defaqto5: file(relativePath: { eq: "2020/defaqto_alt5.png" }) {
          ...tblStarImage3
        },
      
      
        bil20195: file(relativePath: { eq: "2020/bil5.png" }) {
          ...tblStarImage
        },
        bil20194: file(relativePath: { eq: "2020/bil4.png" }) {
          ...tblStarImage
        },
        bil20193: file(relativePath: { eq: "2020/bil3.png" }) {
          ...tblStarImage
        }
        bil20192: file(relativePath: { eq: "2020/bil2.png" }) {
          ...tblStarImage
        }
        bil20191: file(relativePath: { eq: "2020/bil1.png" }) {
          ...tblStarImage
        }
        hus20195: file(relativePath: { eq: "2020/hus5.png" }) {
          ...tblStarImage
        },
        hus20194: file(relativePath: { eq: "2020/hus4.png" }) {
          ...tblStarImage
        },
        hus20193: file(relativePath: { eq: "2020/hus3.png" }) {
          ...tblStarImage
        }
        hus20192: file(relativePath: { eq: "2020/hus2.png" }) {
          ...tblStarImage
        }
        hus20191: file(relativePath: { eq: "2020/hus1.png" }) {
          ...tblStarImage
        }                
        reise20195: file(relativePath: { eq: "2020/reise5.png" }) {
          ...tblStarImage
        },
        reise20194: file(relativePath: { eq: "2020/reise4.png" }) {
          ...tblStarImage
        },
        reise20193: file(relativePath: { eq: "2020/reise3.png" }) {
          ...tblStarImage
        }
        reise20192: file(relativePath: { eq: "2020/reise2.png" }) {
          ...tblStarImage
        }
        reise20191: file(relativePath: { eq: "2020/reise1.png" }) {
          ...tblStarImage
        }                    
        innbo20195: file(relativePath: { eq: "2020/innbo5.png" }) {
          ...tblStarImage
        },
        innbo20194: file(relativePath: { eq: "2020/innbo4.png" }) {
          ...tblStarImage
        },
        innbo20193: file(relativePath: { eq: "2020/innbo3.png" }) {
          ...tblStarImage
        },
        innbo20192: file(relativePath: { eq: "2020/innbo2.png" }) {
          ...tblStarImage
        },
        innbo20191: file(relativePath: { eq: "2020/innbo1.png" }) {
          ...tblStarImage
        }
        bat20195: file(relativePath: { eq: "2020/bat5.png" }) {
          ...tblStarImage
        },
        bat20194: file(relativePath: { eq: "2020/bat4.png" }) {
          ...tblStarImage
        },
        bat20193: file(relativePath: { eq: "2020/bat3.png" }) {
          ...tblStarImage
        },
        bat20192: file(relativePath: { eq: "2020/bat2.png" }) {
          ...tblStarImage
        },
        bat20191: file(relativePath: { eq: "2020/bat1.png" }) {
          ...tblStarImage
        }
        barn20195: file(relativePath: { eq: "2020/barn5.png" }) {
          ...tblStarImage
        },
        barn20194: file(relativePath: { eq: "2020/barn4.png" }) {
          ...tblStarImage
        },
        barn20193: file(relativePath: { eq: "2020/barn3.png" }) {
          ...tblStarImage
        },
        barn20192: file(relativePath: { eq: "2020/barn2.png" }) {
          ...tblStarImage
        },
        barn20191: file(relativePath: { eq: "2020/barn1.png" }) {
          ...tblStarImage
        }
      }
    `}
    render={data => (
      <>
        <table className={"comparisonTable"}>
          <tbody>
          {showHeaders === true &&
            <tr>
              {showProvider === true &&
                <th>Selskap</th>
              }
              <th>Produktnavn</th>
              <th>Kvalitet</th>
            </tr>
          }
          {objList.edges.map(({node: forsikring}) => {
            if(forsikring.Defaqto_Stjerne >= betterThan){
              return(
              <tr key={forsikring.Provider+forsikring.Product+forsikring.Defaqto_Stjerne}>
                {showProvider === true &&
                  <td><Link to={'/'+slugifier(forsikring.Provider)}>{forsikring.Provider}</Link></td>
                }
                <td><Link to={'/'+siteSection+'/'+slugifier(forsikring.Provider)+'/'+slugifier(forsikring.Product)}>{forsikring.Product}</Link></td>
                <td>
                  {
                    forsikring.Defaqto_Stjerne > 0 &&

                    <>{/*<Img className="moto-content-image" alt={forsikring.Provider + " " + forsikring.Product + " -  " + forsikring.Defaqto_Stjerne.toString() + " Defaqto stjerner"} fixed={
                      (forsikring.Defaqto_Stjerne === "5" && siteSection === "bil" && Year === "2019" && data.bil20195.childImageSharp.fixed) ||
                      (forsikring.Defaqto_Stjerne === "4" && siteSection === "bil" && Year === "2019" && data.bil20194.childImageSharp.fixed) ||
                      (forsikring.Defaqto_Stjerne === "3" && siteSection === "bil" && Year === "2019" && data.bil20193.childImageSharp.fixed) ||
                      (forsikring.Defaqto_Stjerne === "5" && siteSection === "hus" && Year === "2019" && data.hus20195.childImageSharp.fixed) ||
                      (forsikring.Defaqto_Stjerne === "4" && siteSection === "hus" && Year === "2019" && data.hus20194.childImageSharp.fixed) ||
                      (forsikring.Defaqto_Stjerne === "3" && siteSection === "hus" && Year === "2019" && data.hus20193.childImageSharp.fixed) ||
                      (forsikring.Defaqto_Stjerne === "5" && siteSection === "reise" && Year === "2019" && data.reise20195.childImageSharp.fixed) ||
                      (forsikring.Defaqto_Stjerne === "4" && siteSection === "reise" && Year === "2019" && data.reise20194.childImageSharp.fixed) ||
                      (forsikring.Defaqto_Stjerne === "3" && siteSection === "reise" && Year === "2019" && data.reise20193.childImageSharp.fixed) ||
                      (forsikring.Defaqto_Stjerne === "5" && siteSection === "innbo" && Year === "2019" && data.innbo20195.childImageSharp.fixed) ||
                      (forsikring.Defaqto_Stjerne === "4" && siteSection === "innbo" && Year === "2019" && data.innbo20194.childImageSharp.fixed) ||
                      (forsikring.Defaqto_Stjerne === "3" && siteSection === "innbo" && Year === "2019" && data.innbo20193.childImageSharp.fixed)
                    }/>
                    <br/>*/}
                      {/*<small><nobr>{forsikring.Defaqto_Stjerne} stjerner</nobr></small>*/}
                      {
                      <Image className="moto-content-image defaqto_img"
                             style={{maxWidth:'101px'}}
                             alt={forsikring.Defaqto_Stjerne.toString() + " Defaqto stjerner"}
                             title={forsikring.Provider + " " + forsikring.Product + " - denne forsikringen har oppnådd " + forsikring.Defaqto_Stjerne.toString() + " Defaqto stjerner"}
                             fluid={
                              (forsikring.Defaqto_Stjerne === "5" && data.defaqto5.childImageSharp.fluid) ||
                              (forsikring.Defaqto_Stjerne === "4" && data.defaqto4.childImageSharp.fluid) ||
                              (forsikring.Defaqto_Stjerne === "3" && data.defaqto3.childImageSharp.fluid) ||
                              (forsikring.Defaqto_Stjerne === "2" && data.defaqto2.childImageSharp.fluid) ||
                              (forsikring.Defaqto_Stjerne === "1" && data.defaqto1.childImageSharp.fluid)
                        }/>
                      }
                    </>

                  }
                  {forsikring.Defaqto_Stjerne == 0 && <small><nobr>Ikke vurdert<sup>*</sup></nobr></small>}
                </td>
              </tr>)
            }
          })}
          </tbody>
        </table>

        {/*
        <div className="moto-widget moto-widget-row__column moto-cell col-sm-4 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
          <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sama" data-visible-on="mobile-v" data-animation>
            <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_normal" style={{textAlign: 'center'}}><span className="moto-content-image-plugin-wrapper moto-spacing-top-zero moto-spacing-right-zero moto-spacing-bottom-zero moto-spacing-left-zero"><span className="moto-content-image-container">
              <Img className="moto-content-image" alt={Provider + " " + Product + " -  " + Stars.toString() + " Defaqto stjerner"} fixed={
                (Stars === "5" && Section === "bil" && Year === "2019" && data.bil20195.childImageSharp.fixed) ||
                (Stars === "4" && Section === "bil" && Year === "2019" && data.bil20194.childImageSharp.fixed) ||
                (Stars === "3" && Section === "bil" && Year === "2019" && data.bil20193.childImageSharp.fixed) ||
                (Stars === "5" && Section === "hus" && Year === "2019" && data.hus20195.childImageSharp.fixed) ||
                (Stars === "4" && Section === "hus" && Year === "2019" && data.hus20194.childImageSharp.fixed) ||
                (Stars === "3" && Section === "hus" && Year === "2019" && data.hus20193.childImageSharp.fixed) ||
                (Stars === "5" && Section === "reise" && Year === "2019" && data.reise20195.childImageSharp.fixed) ||
                (Stars === "4" && Section === "reise" && Year === "2019" && data.reise20194.childImageSharp.fixed) ||
                (Stars === "3" && Section === "reise" && Year === "2019" && data.reise20193.childImageSharp.fixed) ||
                (Stars === "5" && Section === "innbo" && Year === "2019" && data.innbo20195.childImageSharp.fixed) ||
                (Stars === "4" && Section === "innbo" && Year === "2019" && data.innbo20194.childImageSharp.fixed) ||
                (Stars === "3" && Section === "innbo" && Year === "2019" && data.innbo20193.childImageSharp.fixed)
              }/></span></span></p><p className="moto-text_normal" style={{textAlign: 'center'}}><span className="moto-color2_1"><Link to={"/"+Section+"/"+Year+"/"+slugifier(Provider)+"/"+slugifier(Product)}><strong>{Provider} {Product}</strong></Link></span></p></div>
          </div>
        </div>
        */}
      </>
    )}
  />
)

export default ComparisonTable

ComparisonTable.propTypes = {
  betterThan: PropTypes.number,
  showHeaders: PropTypes.bool,
  showProvider: PropTypes.bool
}

ComparisonTable.defaultProps = {
  betterThan: 0,
  showHeaders: true,
  showProvider: true
}

function slugifier(input) {

  let slugString = input;

  return slugString
  //.toLowerCase()
    .replace(/æ/g,'ae')
    .replace(/ø/g,'o')
    .replace(/å/g,'aa')
    .replace(/&/g,'og')
    .replace(/[^a-zA-Z0-9\/]+/g, '-')
    .replace(/\/-/g,'/')
    .slice(0, 96)                                             // Setting max length to 96
    .replace(/-$/g,'')
}